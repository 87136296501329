const tecnoList = [
    'AWS',
    'Lambda',
    'Node',
    'TypeScript',
    'JavaScript',
    // 'Express',
    // 'Nest.js',
    'JAVA',
    'C#',
    'DotNet',
    'API',
    'MySQL',
    // 'TypeORM',
    'MongoDB',
    'DynamoDB',
    // 'Sequelize',
    'Git',
    // 'Téc Ágeis',
    'Scrum',
    'Kanban',
    'React',
    'Vue',
    'HTML',
    'CSS',
    // 'Bootstrap',
];

export default tecnoList;